import React from 'react'
import {
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  Show,
  useUnique,
  TextInput,
  required,
  email,
  Edit,
  SimpleForm,
  EditButton,
  ReferenceField,
  FunctionField,
  SimpleShowLayout,
  BooleanField,
  TabbedShowLayout,
} from 'react-admin'
import constants from 'utils/constants'
import EditToolbar from 'admin/components/EditToolbar'
import MergeButton from 'admin/components/MergeButton'
import DateTimeField from 'admin/components/DateTimeField'
import i18next from 'i18next'
import { DocumentGroup } from 'employee/types/document_groups'
import { Grid } from '@mui/material'
import { DocumentGroupStatus } from 'types/document_groups'
import Button from 'components/Button'
import BirthdateInput from 'admin/components/BirthdateInput'
import SocialSecurityNumberInput from 'admin/components/SocialSecurityNumberInput'
import List from 'admin/components/List'

const colorByStatus = (status: DocumentGroupStatus) => {
  if (status === 'not_owned') {
    return '#FFFFC2'
  }
  return status === 'valid' ? '#efe' : '#ffcbd1'
}

const backgroundColorByStatus = (record: { status: DocumentGroupStatus }) => ({
  backgroundColor: colorByStatus(record.status),
})

export const EmployeesShow = () => {
  const [showAllDocuments, setShowAllDocuments] = React.useState(false)

  const documentSource = showAllDocuments ? 'documents' : 'current_documents'

  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Intérimaire">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <TextField source="email" />
                <DateField source="birthdate" />
                <TextField source="social_security_number" />
                <TextField source="phone_number" />
                <DateField source="created_at" />
                <DateField source="updated_at" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <ArrayField source="agencies">
                  <Datagrid resource="agencies" bulkActionButtons={false}>
                    <TextField source="name" />
                    <TextField source="status" />
                    <ReferenceField source="agent_id" reference="agents" link="show" label="Agent">
                      <TextField source="first_name" /> <TextField source="last_name" />
                    </ReferenceField>
                  </Datagrid>
                </ArrayField>
              </SimpleShowLayout>
            </Grid>
          </Grid>
          <ArrayField source="document_groups" label="Dossier de l'intérimaire">
            <Datagrid bulkActionButtons={false} rowSx={backgroundColorByStatus}>
              <TextField source="label" />
              <FunctionField
                source="status"
                render={(record: DocumentGroup) => i18next.t(`document_groups.status.${record.status}`)}
              />
              <DateField source="expiration_date" />
            </Datagrid>
          </ArrayField>
          <Button name="show_all" onClick={() => setShowAllDocuments(!showAllDocuments)} className="my-4">
            {showAllDocuments
              ? "Voir uniquement les documents du dossier actuel de l'intérimaire"
              : "Voir tous les documents de l'intérimaire"}
          </Button>
          <ArrayField source={documentSource} resource="documents" label="Documents de l'intérimaire">
            <Datagrid resource="documents" bulkActionButtons={<MergeButton />} rowClick="edit">
              <DateTimeField source="created_at" />
              <TextField source="label" />
              <TextField source="verification_status" />
              <TextField source="expiration_date" />
              {showAllDocuments && (
                <BooleanField label="Document dans le dossier actuel intérimaire" source="is_current" />
              )}
              <EditButton resource="documents" />
            </Datagrid>
          </ArrayField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Canal trackers">
          <ArrayField source="canal_trackers" label={false}>
            <Datagrid resource="canal_trackers" bulkActionButtons={false} rowClick={false}>
              <TextField source="id" />
              <TextField source="template" />
              <DateTimeField source="created_at" />
            </Datagrid>
          </ArrayField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const EmployeesList = () => (
  <List>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="phone_number" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

const EmployeeInputs = () => {
  const unique = useUnique()
  return (
    <>
      <TextInput source="first_name" validate={required()} />
      <TextInput source="last_name" validate={required()} />
      <BirthdateInput source="birthdate" />
      <SocialSecurityNumberInput source="social_security_number" label="Numéro de sécurité sociale" />
      <TextInput source="email" type="email" validate={[required(), email(), unique()]} />
      <TextInput source="phone_number" type="phone_number" />
    </>
  )
}

export const EmployeeEdit = () => (
  <Edit mutationMode={constants.IS_TEST_ENV ? 'pessimistic' : 'undoable'}>
    <SimpleForm toolbar={<EditToolbar />}>
      <EmployeeInputs />
    </SimpleForm>
  </Edit>
)

type Employee = {
  id: string
  first_name: string
  last_name: string
  email: string
  created_at: string
  updated_at: string
}

export const employeeRecordRepresentation = (record: Employee) =>
  `${record.first_name} ${record.last_name} (${record.id})`
